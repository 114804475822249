import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from 'lib/utils';

const buttonVariants = cva(
  'gap-1 inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:text-opacity-40 shrink-0',
  {
    variants: {
      variant: {
        accent: 'bg-accent text-white hover:opacity-80',
        default: 'bg-primary text-primary-foreground hover:bg-primary/90',

        destructive: 'bg-destructive text-destructive-foreground hover:bg-destructive/90',
        outline: 'border border-border bg-background/0 hover:bg-muted',
        // secondary: 'bg-foreground/10 text-foreground hover:bg-foreground/20',
        secondary:
          'bg-muted-foreground/10 dark:bg-muted-foreground/20 text-blue-600 dark:text-blue-400 hover:bg-muted-foreground/20 dark:hover:bg-muted-foreground/10',
        ghost: 'hover:bg-secondary',
        link: 'underline-offset-4 hover:underline text-blue-800 dark:text-blue-300',
        overlay:
          'bg-black/60 text-white hover:bg-white/50 rounded-full ring-1 ring-white ring-opacity-10 hover:ring-opacity-30 hover:bg-neutral-800 focus-visible:ring-0 focus-visible:ring-ring focus-visible:ring-offset-0'
      },
      size: {
        default: 'h-10 px-4 py-2',
        xs: 'h-7 px-3 py-2 text-xs',
        sm: 'max-h-9 h-9 rounded-md px-3',
        lg: 'h-11 rounded-md px-8',
        xl: 'h-14 rounded-md px-8',
        icon: 'h-10 w-10'
      },
      align: {
        left: 'w-full gap-3 flex justify-start px-4',
        center: ''
      }
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
      align: 'center'
    }
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, align, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';
    return <Comp className={cn(buttonVariants({ variant, size, align, className }))} ref={ref} {...props} />;
  }
);
Button.displayName = 'Button';

export { Button, buttonVariants };
